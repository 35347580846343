<template>
  <!-- <div class="entry-content"> -->
    <!-- <nav aria-label="breadcrumb">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#"><i class="bi bi-house-door"></i></a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">{{ $t("My_Account") }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ $t("my_info") }}
          </li>
        </ol>
      </div>
    </nav> -->
    <!-- <div class="container">
      <div class="row"> -->
        <!-- <div class="col-md-3">
                    <ul class="menu-user">
                         <li><a href=""><span><img src="@/main/assets/images/user-icon.svg" alt=""></span> {{$t('my_info')}}</a></li>
                        <li><a href=""><span><img src="@/main/assets/images/history.svg" alt=""></span> {{$t('The_date_of_order')}}</a></li>
                        <li><a href=""><span><img src="@/main/assets/images/address.svg" alt=""></span> {{$t('my_addresses')}}</a></li>
                        <li><a href=""><span><img src="@/main/assets/images/006-close-envelope.svg" alt=""></span> {{$t('change_Password')}}</a></li>
                        <li><a href=""><span><img src="@/main/assets/images/logout.svg" alt=""></span> {{$t('sign_out')}}</a></li>
                    </ul>
                </div> -->
       
            <div class="col-md-10">
              <div class="entry-content-myaccount form address">
                <h2>{{ $t("personal_information") }}</h2>

                <div class="row justify-content-center">
                  <div class="col-md-10">
                    <form @submit.prevent="update">
                      <div class="row">
                        <!-- <div class="col-md-12 mb-3">
                          <label>{{ $t("old_pass") }}</label>
                          <div class="input-group">
                            <span class="input-group-text"
                              ><i class="fas fa-key"></i
                            ></span>
                            <input
                              v-model="user.old_password"
                              type="password"
                              class="form-control"
                              :placeholder="$t('add_old_pass')"
                               @blur="validate('old_password')" 
                              @keypress="validate('old_password')"
                            />
                          </div>
                       <div class="errors" v-if="!!errors.old_password">{{ errors.old_password }}</div>
                        </div> -->
                        <div class="col-md-12 mb-3">
                          <label>{{ $t("new_pass") }}</label>
                          <div class="input-group">
                            <span class="input-group-text"
                              ><i class="fas fa-lock"></i
                            ></span>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              :placeholder="$t('add_new_pass')"
                               @blur="validate('password')" 
                              @keypress="validate('password')"
                            />
                          </div>
                          <div class="errors" v-if="!!errors.password">{{ errors.password }}</div>
                        </div>
                        <div class="col-md-12 mb-3">
                          <label>{{ $t("confirm_pass") }}</label>
                          <div class="input-group">
                            <span class="input-group-text"
                              ><i class="fas fa-lock"></i
                            ></span>
                            <input
                              v-model="user.confirm_password"
                              type="password"
                              class="form-control"
                              :placeholder="$t('add_confirm_pass')"
                               @blur="validate('confirm_password')" 
                                @keypress="validate('confirm_password')"
                            />
                          </div>
                          <div class="errors" v-if="!!errors.confirm_password">{{ errors.confirm_password }}</div>
                        </div>
                      </div>
                      <button type="submit" class="button mt-4">
                        {{ $t("Confirm_the_change") }}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
          
        </div>
      <!-- </div>
    </div> -->
  <!-- </div> -->
</template>
<script>
import { mapState } from "vuex";
import * as Yup from "yup";
const resetPasswordFormSchema = Yup.object().shape({
  old_password: Yup.string(),
  password: Yup.string().min(8, "Password should be less than 8 characters")
    .required("Password is required"),
     confirm_password: Yup.string().min(8, "Confirm Password should be less than 8 characters")
    .oneOf([Yup.ref('password'), null], 'Passwords must match').required("Confirm Password is required"),
});


export default {
   data() {
    return {
      errors: { old_password: "", password: "", confirm_password: "" },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user.user,
    }),
  },
  methods: {
    update() {
      resetPasswordFormSchema
        .validate(this.user, { abortEarly: false })
        .then(() => {
           let loader = this.$loading.show({
            canCancel: false, // default false
            color: "#7c4707",
            loader: "spinner",
            width: 64,
            height: 64,
            backgroundColor: "#ffffff",
            opacity: 0.7,
            zIndex: 999,
          });
          this.$store.dispatch("user/update", this.user).then((data) => {
              this.user.password = "";
              this.user.confirm_password = "";
              loader.hide();
              this.$swal.fire({
                title: this.$t("success"),
                 text: this.$t("success"),
                icon: "success",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
          }).catch(err => {
            loader.hide();
            this.$swal.fire({
              title: this.$t("error"),
              text: err,
              icon: "error",
              confirmButtonText: this.$t("Ok"),
              confirmButtonColor: "#41b882",
            });
          });
        })
        .catch(err => {
          err.inner.forEach(error => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
     validate(field) {
      resetPasswordFormSchema.validateAt(field, this.user)
        .then(() => (this.errors[field] = ""))
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>
<style scoped>
.errors {
  color: red;
}
</style>