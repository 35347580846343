<template>
  <div class="col-md-10">
    <div class="entry-content-myaccount address form">
      <h2>{{ $t("Add_a_new_site") }}</h2>
      <form @submit.prevent="save()">
        <div class="row">
          <div class="col-md-12">
           
          <div class="mb-3">
            <label>{{ $t("street_address") }} <span>*</span></label>
            <input
              type="text"
              v-model="item.street_address"
              class="form-control"
              :placeholder="$t('street_address')"
              @blur="validate('street_address')" 
              @keypress="validate('street_address')"
            />
             <div class="errors" v-if="!!errors.street_address">{{ errors.street_address }}</div>
          </div>

          <div class="mb-3">
            <label
              >Apt, Suit, Building ({{ $t("optional") }})<span>*</span></label
            >
            <input
              type="text"
              v-model="item.apt_suit_building"
              class="form-control"
              placeholder="apt suit building"
              @blur="validate('apt_suit_building')" 
              @keypress="validate('apt_suit_building')"
            />
          </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>Zip Code <span>*</span></label>
              <input
                type="text"
                v-model="item.zip_code"
                class="form-control"
                placeholder="Zip Code"
                @blur="validate('zip_code')" 
                @keypress="validate('zip_code')"
              />
               <div class="errors" v-if="!!errors.zip_code">{{ errors.zip_code }}</div>
            </div>
           
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>{{ $t("city") }} <span>*</span></label>
              <input
                type="text"
                v-model="item.city"
                class="form-control"
                :placeholder="$t('city')"
                @blur="validate('city')" 
                @keypress="validate('city')"
              />
             <div class="errors" v-if="!!errors.city">{{ errors.city }}</div>
            </div>
          </div>
    <div class="col-md-6">
              <div class="mb-3">
                <label>{{ $t("STATE") }} <span>*</span></label>
                <input
                  type="text"
                  v-model="item.state"
                  class="form-control"
                  :placeholder="$t('STATE')"
                   @blur="validate('state')" 
                    @keypress="validate('state')"
                />
              <div class="errors" v-if="!!errors.state">{{ errors.state }}</div>
              </div>
            </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>{{ $t("country_region") }} <span>*</span></label>
              <input
                type="text"
                v-model="item.country_region"
                class="form-control"
                :placeholder="$t('country_region')"
                 @blur="validate('country_region')" 
                  @keypress="validate('country_region')"
              />
            <div class="errors" v-if="!!errors.country_region">{{ errors.country_region }}</div>
            </div>
          </div>
        </div>
        <br>
        <button type="submit" class="button">{{ $t("add_site") }}</button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as Yup from "yup";
const addressFormSchema = Yup.object().shape({
  street_address: Yup.string().required("Street address is required"),
  apt_suit_building: Yup.string(),
  zip_code: Yup.string().required("Zip code is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country_region: Yup.string().required("Country/Region is required"),
});

export default {
  data() {
    return {
      item: {},
      errors: { street_address: "", apt_suit_building: "", zip_code: "", city:"", state:"", country_region :""},
    };
  },
  mounted() {
    if (this.$route.params.eid) {
      this.$store.dispatch("address/show", { id: this.$route.params.eid });
    }
  },
  methods: {
    save() {
    
       addressFormSchema
        .validate(this.item, { abortEarly: false })
        .then(() => {
      this.item.user_id = this.user.id;
      this.item.email = this.user.email;
      this.item.phone_number = this.user.mobile;
      this.item.first_name = this.user.name;
      this.item.last_name = this.user.user_name;
          let loader = this.$loading.show({
            canCancel: false, // default false
            color: "#7c4707",
            loader: "spinner",
            width: 64,
            height: 64,
            backgroundColor: "#ffffff",
            opacity: 0.7,
            zIndex: 999,
          });
          this.$store.dispatch("address/store", this.item).then((data) => {
            loader.hide();
               console.log(data)
            if (data && data.street_address) {
               this.$swal.fire({
                title: this.$t("success"),
                text: this.$t("success"),
                icon: "success",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
               })


               
            }
             this.$router.push("/my-account/my_addresses");
          })
            .catch(err => {
              loader.hide();
              this.$swal.fire({
                title: this.$t("error"),
                text: err,
                icon: "error",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
              
            });
       })
         .catch(err => {
          console.log(err)
          err.inner.forEach(error => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
     validate(field) {
      addressFormSchema.validateAt(field, this.item)
        .then(() => (this.errors[field] = ""))
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user.user,
      one: (state) => state.address.one,
    }),
  },
  watch: {
    one(val) {
      if (val) {
        this.item = JSON.parse(JSON.stringify(val));
      }
    },
  },
};
</script>
<style scoped>
.errors {
  color: red;
}
</style>