<template>
  <div class="col-md-10">
    <div class="entry-content-myaccount form">
      <h2>{{$t('account_info')}}</h2>

      <form @submit.prevent="update">
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="input-group">
              <span class="input-group-text"><i class="fas fa-user"></i></span>
              <input
              disabled
                type="text"
                class="form-control"
                v-model="user.name"
                :placeholder="$t('full_name')"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group">
              <span class="input-group-text"><i class="fas fa-user"></i></span>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('enter_username')"
                v-model="user.user_name"
                  @blur="validate('user_name')" 
                    @keypress="validate('user_name')"
              />
            </div>
             <div class="errors" v-if="!!errors.user_name">{{ errors.user_name }}</div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="input-group">
              <span class="input-group-text"
                ><i class="fas fa-mobile-alt"></i
              ></span>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('mobile_no')"
                v-model="user.mobile"
                      @blur="validate('mobile')" 
                      @keypress="validate('mobile')"
              />
            </div>
              <div class="errors" v-if="!!errors.mobile">{{ errors.mobile }}</div>
          </div>
          <div class="col-md-6">
            <div class="input-group">
              <span class="input-group-text"
                ><i class="fas fa-envelope"></i
              ></span>
              <input
                type="email"
                disabled
                class="form-control"
                v-model="user.email"
                :placeholder="$t('email')"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="button">{{ $t("update") }}</button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const userFormSchema = Yup.object().shape({
  user_name: Yup.string()
    .required("User Name is required"),
 mobile: Yup.string().matches(phoneRegExp, 'Mobile Number is not valid').required("Mobile Number is required"),
});

export default {
   data() {
    return {
      errors: {
        user_name: "",
        mobile: ""
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user.user,
      userUpdate: (state) => state.auth.user,
    }),
  },
  methods: {
    update() {
      userFormSchema
        .validate(this.user, { abortEarly: false })
        .then(() => {
      let user = this.userUpdate;
      this.$store.dispatch("user/update", this.user).then(
        
            localStorage.user_data = JSON.stringify({
                user
       }),
        this.$swal.fire({
            title: this.$t("success"),
            text: this.$t("success"),
            icon: "success",
            confirmButtonText: this.$t("Ok"),
            confirmButtonColor: "#41b882",
          }),
          );
      })
    },
     validate(field) {
      userFormSchema.validateAt(field, this.user)
        .then(() => (this.errors[field] = ""))
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
  mounted() {
    document.title = 'ruknalfakhamah | ' + this.$t(this.$route.name);

  },
};
</script>
<style scoped>
.errors {
  color: red;
}
</style>